<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <v-row justify="center" >
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-avatar size="164">
                <v-img
                  v-if="entity.groupId === null"
                  :src="require(`@/assets/images/${entity.avatar}`)"
                ></v-img>
                <v-img
                  v-else
                  :src="entity.avatar"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
            >
              <v-card flat>
                <kn-form-title :title="entity.nombre" routerName="Alumnos" />
                <v-card-text>
                  <p class="mb-1">Grupo: {{ entity.grupo }}</p>
                  <p class="mb-1">No. Credencial: {{ entity.numero_credencial_alumno }}</p>
                  <p class="mb-1">Tel: {{ entity.datos_personales.telefono_movil }}</p>
                  <p class="mb-1">{{ fullAddressStr }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    tile
                    color="primary"
                    small
                    @click="goToStudentInfo"
                  >Editar información</v-btn>
                </v-card-actions>
              </v-card>
              <v-card flat>
                <v-card-text>
                  <v-row class="my-3">
                    <v-btn
                      color="info"
                      text
                      tile
                      @click="setItems('Pendiente de pago')"
                    >
                      <span class="text-none">Pendiente de pago</span>
                    </v-btn>
                    <v-divider class="info" vertical />
                    <v-btn
                      color="info"
                      text
                      tile
                      @click="setItems('Pagado')"
                    >
                      <span class="text-none">Pagado</span>
                    </v-btn>
                    <v-divider class="info" vertical />
                    <v-btn
                      color="info"
                      text
                      tile
                      @click="addNewCharge"
                    >
                      <span class="text-none">Agregar cargo</span>
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="!isCharge"
            align="center"
            no-gutters
          >
            <v-col
              cols="4"
              md="1"
              lg="1"
              xl="1"
              class="text-center graydefault--text"
            >Del</v-col>
            <v-col
              cols="8"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="dateRange.from"
                dense
                outlined
                type="date"
                hide-details
              />
            </v-col>
            <v-col
              cols="4"
              md="1"
              lg="1"
              xl="1"
              class="text-center graydefault--text"
            >Al</v-col>
            <v-col
              cols="8"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="dateRange.to"
                dense
                outlined
                type="date"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-btn
                class="ml-2"
                tile
                color="primary"
                :block="$vuetify.breakpoint.mobile"
                @click="filterByDateRange"
              >
                Filtrar
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="!isCharge">
            <v-col cols="12">
              <v-card flat tile>
                <v-card-title class="py-2 gray1 purple--text">
                  Estado de cuenta - {{ tableTitle }}
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  loading-text="Cargando... Espera por favor"
                  no-data-text="Aún no hay registros"
                  no-results-text="No se encontraron coincidencias"
                  :footer-props="{
                    itemsPerPageText: 'Elementos por página'
                  }"
                >
                  <template v-slot:[`item.fecha_elaboracion`]="{ item }">
                      {{ item.fecha_elaboracion.substring(0, 10) }}
                  </template>
                  <template v-slot:[`item.total_ingreso`]="{ item }">
                    <span class="green--text">
                      {{ item.total_ingreso }}
                    </span>
                  </template>
                  <template v-slot:[`item.total_adeudo`]="{ item }">
                    <span class="green--text">
                      {{ item.total_adeudo }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <section v-if="isCharge">
            <kn-form-subtitle title="Agregar cargo" />
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <kn-text-field
                class="mt-4"
                v-model="newCharge.nombre_adeudo"
                label="Concepto:"
                :rules="[rules.required]"
              />
              <kn-text-field
                v-model.number="newCharge.sub_total"
                label="Subtotal:"
                :rules="[rules.required]"
                @input="calculateTotal"
              />
              <kn-text-field
                v-model.number="newCharge.total_impuestos"
                label="Total impuestos:"
                :rules="[rules.required]"
                @input="calculateTotal"
              />
              <kn-text-field
                v-model.number="newCharge.total_descuento"
                label="Total descuento:"
                :rules="[rules.required]"
                @input="calculateTotal"
              />
              <kn-text-field
                v-model.number="newCharge.total_adeudo"
                label="Total:"
                :rules="[rules.required]"
              />
              <kn-select
                v-model="newCharge.id_moneda"
                label="Moneda:"
                :rules="[rules.required]"
                :items="coins"
                item-value="id"
                item-text="nombre_moneda"
              />
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Fecha vencimiento:</v-subheader>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-text-field
                    v-model="newCharge.fecha_vencimiento"
                    dense
                    outlined
                    :rules="[rules.required]"
                    type="date"
                    hint="DD / MM / YYYY"
                    persistent-hint
                  >
                    <template v-slot:append-outer>
                      <v-icon color="primary">mdi-calendar-month-outline</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Fecha pronto pago:</v-subheader>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-text-field
                    v-model="newCharge.fecha_pronto_pago"
                    dense
                    outlined
                    :rules="[rules.required]"
                    type="date"
                    hint="DD / MM / YYYY"
                    persistent-hint
                  >
                    <template v-slot:append-outer>
                      <v-icon color="primary">mdi-calendar-month-outline</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <kn-select
                v-model="newCharge.id_penalizacion"
                label="Penalización:"
                :rules="[rules.required]"
                :items="penalties"
                item-value="id"
                item-text="nombre"
              />
              <kn-select
                v-model="newCharge.id_descuento_pronto_pago"
                label="Descuento pronto pago:"
                :rules="[rules.required]"
                :items="promptPaymentDiscounts"
                item-value="id"
                item-text="nombre_descuento"
              />
              <kn-select
                v-model="newCharge.id_estatus_adeudo"
                label="Estatus del cargo:"
                :rules="[rules.required]"
                :items="debtStatus"
                item-value="id"
                item-text="dato"
              />
              <kn-text-area
                v-model="newCharge.comentarios"
                label="Comentarios:"
                :rules="[rules.required]"
              />
            </v-form>
            
            <kn-form-action-buttons
              :small="true"
              @accept="createDebt()"
              @cancel="cleanDebt()"
            />
          </section>
        </v-col>
      </v-row>
    </v-container>
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loadingAlert"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api/axios-base';
import KnFormActionButtons from '@/components/KnFormActionButtons.vue';
import KnFormSubtitle from '@/components/KnFormSubtitle.vue';
import KnLocalAlert from '@/components/KnLocalAlert.vue';
import { addressUtilsMixin } from '@/mixins/addressUtilsMixin'
import { utilsMixin } from '@/mixins/utilsMixin'
import { apiMixin } from '@/mixins/apiMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { arrayUtilsMixin } from '@/mixins/arrayUtilsMixin'
import KnSelect from '@/components/inputs/KnSelect.vue';
import KnFormTitle from '@/components/KnFormTitle.vue';
import KnTextField from '@/components/inputs/KnTextField.vue';
import KnTextArea from '@/components/inputs/KnTextArea.vue';
export default {
  components: {
    KnFormSubtitle,
    KnFormActionButtons,
    KnLocalAlert,
    KnSelect,
    KnFormTitle,
    KnTextField,
    KnTextArea
  },
  mixins: [
    addressUtilsMixin,
    utilsMixin,
    apiMixin,
    validationFormMixin,
    arrayUtilsMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      isCharge: false,
      valid: true,
      tableTitle: 'Pendiente de pago',
      headers: [
        { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
        { text: 'Concepto', value: 'categoria.dato', class: 'purple--text'},
        { text: 'Monto', value: 'total_ingreso', class: 'purple--text'},
        { text: '', value: 'acciones'}
      ],
      items: [],
      studentId: null,
      dateRange: {
        from: '',
        to: ''
      },
      incomeItems: [],
      debtsItems: [],
      coins: [],
      penalties: [],
      promptPaymentDiscounts: [],
      debtStatus: [],
      newCharge: {
        id_alumno: null,
        nombre_adeudo: '',
        comentarios: '',
        sub_total: 0.00,
        total_impuestos: 0.00,
        total_descuento: 0.00,
        total_adeudo: 0.00,
        descuento_aplicado: 0.00,
        id_moneda: 1,
        expirado: false,
        fecha_vencimiento: '',
        fecha_pronto_pago: '',
        id_penalizacion: null,
        id_descuento_pronto_pago: null,
        id_institucion_educativa: null,
        id_estatus_adeudo: null,
        id_autor: null
      },
      errors: [],
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      loadingAlert: false,
      fullAddressStr: null
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData'])
  },
  async created() {
    this.studentId = this.entity.id
    const address = await this.fetchAddresById(this.entity.datos_personales.direccion, this.institutionId)
    this.fullAddressStr = await this.fullAddress(address)
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()
    this.dateRange.from = this.getFirstDayOfMonth(currentYear, currentMonth)
    this.dateRange.to = this.getLastDayOfMonth(currentYear, currentMonth)
    const filterObj = {
      id_alumno: this.studentId,
      fecha_inicio: this.dateRange.from,
      fecha_fin: this.dateRange.to,
      id_institucion_educativa: this.institutionId
    }
    const incomeItems = await this.fetchIncomeByStudent(filterObj)
    const debtsItems = await this.fetchDebtsByStudent(filterObj)
    this.incomeItems = incomeItems
    this.debtsItems = debtsItems
    // console.log('incomeItems: ', incomeItems);
    // console.log('debtsItems', debtsItems);
    this.setItems('Pendiente de pago')
  },
  methods: {
    async fetchIncomeByStudent(
      {
        id_alumno,
        fecha_inicio,
        fecha_fin,
        id_institucion_educativa
      }
    ) {
      // console.log('id_alumno:', id_alumno);
      // console.log('fecha inicio: ', fecha_inicio);
      try {
        const response = await api.post('/administracion/detalle-ingreso-por-alumno', {
            id_alumno: id_alumno,
            fecha_inicio: fecha_inicio,
            fecha_fin: fecha_fin,
            id_institucion_educativa: id_institucion_educativa
          })
        const responseData = await response.data
        // console.log('Respuesta ingresos por alumno', responseData);
        return responseData.resultado
      } catch (error) {
        console.error('Error al obtener ingresos por alumno.', error);
      }
    },
    async fetchDebtsByStudent(
      {
        id_alumno,
        fecha_inicio,
        fecha_fin,
        id_institucion_educativa
      }
    ) {
      try {
        const response = await api.post('/administracion/adeudos-por-alumno', {
            id_alumno: id_alumno,
            fecha_inicio: fecha_inicio,
            fecha_fin: fecha_fin,
            id_institucion_educativa: id_institucion_educativa
        })
        const responseData = await response.data
        // console.log('Respuesta adeudos por alumno', responseData);
        return responseData.resultado
      } catch (error) {
        console.error('Error al obtener adeudos por alumno.', error);
      }
    },
    async postDebt() {
      try {
        const response = await api.post('/administracion/crear-adeudo', this.newCharge)
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error al intentar agregar cargo')
        } else if (responseData.mensaje) {
          this.errors = []
        }
        // console.log('Respuesta crear adeudo.', responseData);
      } catch (error) {
        console.error('Error al crear adeudo.', error);
      }
    },
    async createDebt() {
      if (this.$refs.form.validate()) {
        this.loadingAlert = true
        this.showAlert = true
        this.alertText = 'Creando cargo'
        this.newCharge.id_alumno = this.studentId
        this.newCharge.id_institucion_educativa = this.institutionId
        this.newCharge.id_autor = this.userData.id
        await this.postDebt()
        
        this.loadingAlert = false
      
        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = 'success'
          this.alertColor = 'success'
          this.alertText = 'Cargo agregado exitosamente!'
        }
      }
    },
    cleanDebt() {
      this.newCharge.comentarios = ''
      this.newCharge.sub_total = 0.00
      this.newCharge.total_impuestos = 0.00
      this.newCharge.total_descuento = 0.00
      this.newCharge.total_adeudo = 0.00
      this.newCharge.descuento_aplicado = false
      this.newCharge.fecha_vencimiento = ''
      this.newCharge.fecha_pronto_pago = ''
      this.$refs.form.resetValidation()
    },
    calculateTotal() {
      let {
        sub_total: subtotal,
        total_impuestos: impuestos,
        total_descuento: descuento,
        total_adeudo: total,
      } = this.newCharge

      impuestos = subtotal * 0.0
      total = (subtotal + impuestos) - descuento
      this.newCharge.total_impuestos = impuestos
      this.newCharge.total_adeudo = total
    },
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1).toISOString().substring(0, 10)
    },
    getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 1 - 1).toISOString().substring(0, 10)
    },
    setItems(option) {
      this.tableTitle = option
      this.isCharge = false
      switch(option) {
        case 'Pendiente de pago':
          this.headers = [
            { text: 'Concepto', value: 'nombre_adeudo', class: 'purple--text'},
            { text: 'Estatus', value: 'estatus.dato', class: 'purple--text'},
            { text: 'Fecha vencimiento', value: 'fecha_vencimiento', class: 'purple--text' },
            { text: 'Fecha pronto pago', value: 'fecha_pronto_pago', class: 'purple--text' },
            { text: 'Comentarios', value: 'comentarios', class: 'purple--text'},
            { text: 'Monto', value: 'total_adeudo', class: 'purple--text'},
            { text: '', value: 'acciones'}
          ]
          this.items = this.debtsItems ? this.debtsItems : []
          break
        case 'Pagado':
          this.headers = [
            { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
            { text: 'Concepto', value: 'categoria.dato', class: 'purple--text'},
            { text: 'Estatus', value: 'estatus.dato', class: 'purple--text'},
            { text: 'Monto', value: 'total_ingreso', class: 'purple--text'},
            { text: '', value: 'acciones'}
          ]
          this.items = this.incomeItems ? this.incomeItems : []
      }
    },
    async filterByDateRange() {
      this.loading = true
      const filterObj = {
        id_alumno: this.studentId,
        fecha_inicio: this.dateRange.from,
        fecha_fin: this.dateRange.to,
        id_institucion_educativa: this.institutionId
      }
      if (this.tableTitle === 'Pendiente de pago') {
        const debtsItems = await this.fetchDebtsByStudent(filterObj)
        this.debtsItems = debtsItems
        // console.log('debtsItems', debtsItems);
      } else if (this.tableTitle === 'Pagado') {
        const incomeItems = await this.fetchIncomeByStudent(filterObj)
        this.incomeItems = incomeItems
        // console.log('incomeItems: ', incomeItems);
      }
      
      this.setItems(this.tableTitle)
      this.loading = false
    },
    async addNewCharge() {
      this.isCharge = true
      if (this.coins && !this.coins.length) {
        const allCoins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
        this.coins = this.activeItems(allCoins)
      }
      if (this.penalties && !this.penalties.length) {
        const allPenalties = await this.fetchResultsByEI('administracion', 'penalizacion', this.institutionId)
        this.penalties = this.activeItems(allPenalties)
      }
      if (this.promptPaymentDiscounts && !this.promptPaymentDiscounts.length) {
        const allPromptPaymentDiscounts = await this.fetchResultsByEI('administracion', 'descuento-pronto-pago', this.institutionId)
        this.promptPaymentDiscounts = this.activeItems(allPromptPaymentDiscounts)
      }
      if (this.debtStatus && !this.debtStatus.length) {
        const allDebtStatus = await this.fetchResultsByEI('administracion', 'estatus-adeudos', this.institutionId)
        this.debtStatus = this.activeItems(allDebtStatus)
      }
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
        this.closeAlert()
        this.cleanDebt()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.cleanDebt()
      this.closeAlert()
    },
    async returnToTable() {
      this.tableTitle = 'Pendiente de pago',
      await this.filterByDateRange()
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    goToStudentInfo() {
      this.$router.push({
        name: 'Editar Alumno',
        params: {
          entity: this.entity
        }
      })
    }
  }
}
</script>

<style>
</style>